<template>
  <div>
    <!-- <b-row>
        <b-col>
          <service-info></service-info>
        </b-col>
      </b-row> -->
   <b-row>
    <b-col class="text-right col-md-12 col-12">
      <b-form-group
        label-cols="11"
        label="Per page"
        class="mb-0 custom-font-18"
      >
        <b-form-select
          class="col-md-12 border custom-font-24"
          v-model="perPage"
          :options="pageOptions"
          @change="init()"
        ></b-form-select>
      </b-form-group>
    </b-col>
   </b-row>
   <div class="mt-2">
    <b-table
      class="text-left custom-font-16"
      stacked="md"
      :items="trxData"
      :busy="isBusy"
      :fields="trxFields"
      :per-page="perPage"
      :current-page="currentPage"
      outlined
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
    >
      <template #cell(_id)="data">
        <b class="text-blue cust_text" style="cursor: pointer;" @click.prevent="editTable(data)"
          >{{ data.item._id }}
        </b>
      </template>
        <template #cell(channel)="data">
         {{ data.item.channel ? channelTypeArray.find(e => e.id == data.item.channel).name : ''}}
      </template>
       <template #cell(banner)="data">
      
       <!-- <b-img fluid :v-if="data.item.banner"  :src="arrayBufferToBase64(data.item.banner)" /> -->
       <b-img fluid @click="openImageModal(data.item._id,'banner')" height="120" width="120"  :src="getImageBanner(data.item._id)" @error="$event.target.src=(`../no_image.png`);" />
   
      </template>
       <template #cell(landing)="data">
      <b-img fluid @click="openImageModal(data.item._id,'landing')" height="120" width="120" :src="getImageLanding(data.item._id)" @error="$event.target.src=(`../no_image.png`);" />
   
      </template>
      <template #cell(netType)="data">
        <!-- {{ data.item.netType ? data.item.netType : '' }}  -->
        {{ checkNetName(data.item.netType) }}
      </template>
      <template #cell(finalUrl)="data">
        <a class="cust_url text-blue" :href="data.item.finalUrl" target="_blank"  rel="noopener noreferrer">{{ data.item.finalUrl }}</a>
      </template>
      <template #table-busy>
        <div class="col-md-12" >
            <h6 class="sub-title mb-0 text-center">Loading...</h6>
            <div class="loader-box">
              <div class="loader-30"></div>
            </div>
        </div> 
      </template> 
    </b-table>
  </div>
    <b-col class="mt-3 p-0 text-right">
      <div class="custom-pagination">
        <a v-show="currentPage != 1" @click="init('prev')">&laquo; Back</a>
        <a class="active">{{currentPage}}</a>
        <a v-show="trxData.length >= 1" @click="init('next')">Next &raquo;</a>
      </div>
    </b-col>
    <!-- <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      class="mt-2"
    ></b-pagination> -->

  </div>
  
</template>

<script>
import ServiceInfo from "../../Dashboard/ServiceInfo.vue";
import dailiesService from "../../../../services/DailyServices";
import NetworkTypeService from '../../../../services/NetworkTypeService'
import config from "../../../../services/globalLit";

export default {
  props: {
    category: {
      type: String | Number,
      required: false,
    },
    trxid: {
      type: String,
    },
    sid: {
      type: String,
    },
    netType:{
      type: String,
    },
    netHost:{
      type: String,
    },
    sdate: {
      type: String | Date,
      required: false,
    },
    edate: {
      type: String | Date,
      required: false,
    },
  },
  components: {
    ServiceInfo,
  },
  mounted() {
    console.log("trx table monted")
    this.init();
  },
  data() {
    return {
      channelTypeArray: config.channelTypeArray,
      data: "",
      showModal: false,
      isBusy: false,
      perPage: 100,
      pageOptions: [100, 200,300, 500],
      currentPage: 1,
      rows: 1,
      trxData: [],
      trxFields: [
        {
          key: "_id",
          label: "ID",
          sortable: true,
        },
        {
          key: "channel",
          label: "Channel",
          sortable: true,
        },
        {
          key: "banner",
          label: "Banner"
        },
        {
          key: "landing",
          label: "Landing"
        },
        {
          key: "netType",
          label: "Network Type"
        },
        {
          key: "finalUrl",
          label: "Final Url"
        }
      ],
      modalimageurl: "",
      networkTypeArray:[],
      sortBy: "_id",
      sortDesc: true,
      sortDirection: "desc",
    };
  },
  watch: {
    trxid: function () {
      console.log("label render");
      this.init();
    },
    netType: function () {
      this.init();
    },
  },
  methods: {
    checkNetName(text){
     let isFound = this.networkTypeArray.find(e => e.id == text)
     let name = isFound ? isFound.name : text  
     return name
    },
    openImageModal(data, type) {
        this.modalimageurl = "";
        switch(type){
          case 'banner':
            this.modalimageurl = this.getImageBanner(data)
            break;
          case 'add':
            this.modalimageurl = this.getImageAdd(data)
            break;
          case 'landing':
            this.modalimageurl = this.getImageLanding(data)
            break;
        }
        // window.open('', '_blank', `width=${screen.width},height=${screen.height}`).document.write(`<html><head><title>Image Popup</title></head><body><img src="${this.modalimageurl}" style="max-width: 100%; height: auto;"></body></html>`);
        const popupWindow = window.open('', '_blank', `width=${screen.width},height=${screen.height}`);
        popupWindow.document.write(`<html><head><title>Image Popup</title></head><body><img src="${this.modalimageurl}" style="max-width: 100%; height: auto;"></body></html>`);
        popupWindow.document.addEventListener('keydown', function(event) {
        if (event.key === 'Escape' || event.keyCode === 27) {
          popupWindow.close();
        }});
      },
    getImageLanding(id){
      return config.getLandingImages+`/${id}`
    },
    getImageBanner(id){
      return config.getBannerImages+`/${id}`
    },
     arrayBufferToBase64(buffer) {
       if(!buffer) return 
      var binary = "";
      var bytes = new Uint8Array(buffer.data);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return binary;
    },
    
    async init(txt) {
      await this.getNetworkType();
      if(txt == 'next'){this.currentPage ++}
          if(txt == 'prev'){
            if(this.currentPage != 1){
            this.currentPage --
            }
          }
      this.isBusy = true
      let payload = {};
      payload.sid = this.sid
      payload.netType = this.netType
      payload.page = this.currentPage
      payload.limit = this.perPage
      payload.netHost = this.netHost
      let endDate = new Date();
      let startDate = new Date(endDate);
      startDate.setUTCHours(0, 0, 0, 0);
      startDate.setUTCMonth(startDate.getUTCMonth() - 1);
      
      if (this.category) {
        let catConf;
        if (typeof this.category === 'string') {
          catConf = config.categorylist.find(e => e.name.toLowerCase() === this.category.toLowerCase()) || {};
        } else if (typeof this.category === 'number') {
          catConf = config.categorylist.find(e => e.id === this.category) || {};
        }

        payload.category = catConf.id;
      }
    
      payload.sdate = this.sdate ? this.sdate : startDate.toISOString();
      payload.edate = this.edate ? this.edate : endDate.toISOString();
      
      let response = await dailiesService.getTrxs(payload);
      if (response.success) {
        this.trxData = response.data;
        this.rows = response.data.length;
      }
      this.isBusy = false
    },
    async getNetworkType() {
        let country = localStorage.getItem('country')
        let response = await NetworkTypeService.getAllNetworkType(country);
        let networkTypeArray = [];
        for (const [key, value] of Object.entries(response.data)) {
          networkTypeArray.push({ id: key, name: value });
        }
        this.networkTypeArray = networkTypeArray;
    },
    toggleBusy() {
      this.isBusy = !this.isBusy;
    },
    editTable(data) {
      // this.data = Object.assign({}, data.item);
      // this.showModal = true;
      let payload = {
        item: {trxid: data.item._id}
      }
    
      this.$root.$emit("openTrx", Object.assign({}, payload));
      // this.$bvModal.show("openServiceInfo");
  
      const url = this.$router.resolve({
          name: 'ViewTrx',
          query: {
              trxid: data.item._id,
              section: 'landings'
          }
      }).href;

      window.open(url, '_blank');
    },
    myRowClickHandler(record, index) {
      // 'record' will be the row data from items
      // `index` will be the visible row number (available in the v-model 'shownItems')
      log(record); // This will be the item data for the row
    },
    log(record) {
      console.log(record);
    },
  },
};
</script>
<style>
.text-blue {
  color: #274AA6
}
.text-red {
  color: #f15a29;
  font-weight: lighter;
}
.table .bTableThStyle {
  background-color: #1c75bc !important;
  /* max-width: '100px' !important; */
  text-overflow: ellipsis !important;
}
.table > tbody > tr > td {
  max-width: 350px !important;
  overflow: auto;
  text-overflow: ellipsis;
}
</style>

<style scoped>
.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  font-size: 12px;
}

.pagination a.active {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
}

.pagination a:hover:not(.active) {background-color: #ddd;cursor: pointer;}
</style>
