<template>
  <b-card class="custom-modal-card-2">
    <span class="custome-title">Top Ad Networks</span>
    <div class="col-md-12" v-if="adNetworkLoading">
      <h6 class="sub-title mb-0 text-center">Loading...</h6>
      <div class="loader-box">
        <div class="loader-30"></div>
      </div>
    </div>
    <GChart
      v-else
      v-show="isVisible"
      class="p-2"
      id="column-chart2"
      :settings="{ packages: ['bar'] }"
      :data="column_chart.chartData_1"
      :options="horizontalChartOptions"
      ref="columnChart"
      :createChart="(el, google) => new google.charts.Bar(el)"
      :events="chartEvents"
      @ready="onChartReady"
    />
  </b-card>
</template>

<script>
import { GChart } from "vue-google-charts";
import DashboardService from "../../../services/DashboardService";
export default {
  props: {
    sdate: {
      type: Date | String,
    },
    edate: {
      type: Date | String,
    },
  },
  watch: {
    sdate: function () {
      this.initColumnChart();
    },
  },
  components: {
    GChart,
  },
  mounted() {
    this.initColumnChart();
  },
  data() {
    return {
      adNetworkLoading: true,
      isVisible: true,
      chartEvents: {
        select: this.columnChartClickHandler,
      },
      chartsLib: null,
      column_chart: {
        chartData_1: [["", ""]],
      },
    };
  },
  computed: {
    horizontalChartOptions() {
      if (!this.chartsLib) return null;
      return this.chartsLib.charts.Bar.convertOptions({
        chart: {
          title: "",
          subtitle: "",
        },
        bars: "horizontal",
        vAxis: {
          format: "none",
          textStyle: { color: "black", fontSize: 16 },
        },
        hAxis: {
          format: "",
          textStyle: { color: "black", fontSize: 16 },
        },
        height: 300,
        width: "100%",
        legend: { position: "none" },
      });
    },
  },
  methods: {
    onChartReady(chart, google) {
      this.chartsLib = google;
    },
    async columnChartClickHandler() {
      let selection = this.$refs.columnChart.chartObject.getSelection();
      if (selection.length > 0) {
        let selectedItem = selection[0];
        let selectedRow = this.column_chart.chartData_1[selectedItem.row + 1];
        if (selectedRow) {
          let payload = {
            netHost: selectedRow[0],
            sdate: this.sdate,
            edate: this.edate,
          };
          this.$root.$emit("showtrxforlist", payload);
          this.$bvModal.show("openSerList");
        }
      }
    },
    async initColumnChart() {
      let adNetworkPayload = {
        sdate: this.sdate,
        edate: this.edate,
      };
      this.isVisible = false;
      this.adNetworkLoading = true;
      try {
        let res = await DashboardService.adNetworkStat(adNetworkPayload);

        if (res.result) {
          let addNetworkData = [["Network Ad Hosts", ""]];
          for (let i = 0; i < res.data.length; i++) {
            addNetworkData.push([
              res.data[i]._id.adNetworkHost,
              res.data[i].total,
            ]);
          }
          this.column_chart.chartData_1 = addNetworkData;
        }
      } catch (e) {
        console.error("error in getting adnetwork data");
        console.log(e);
      }
      this.adNetworkLoading = false;

      this.isVisible = true;
    },
  },
};
</script>

<style scoped>
.custome-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
</style>