<template>
    <div>
      <div style="overflow: auto;">
        <!-- <b-button @click="toggleBusy">Toggle Busy State</b-button> -->
        <b-table class="text-left custom-font-16 mt-2" responsive striped hover :items="serData" :busy="isBusy" :fields="serFields"  :per-page="perPage"
        stacked="md"
        :current-page="currentPage"  outlined show-empty>
          <template #cell(_id)="data">
            <b style="cursor: pointer;" class="text-blue cust_text" @click.prevent="editTable(data)" title="Service provider - Service name" >
              {{sentenceCase(data.item.sprovidername + ' - '  + data.item.sname) }} </b>
          </template>
          <template #cell(category)="data">
              <span class="cust_text">{{ filterCategory(data.item.category) }}</span>
          </template>
          <template #table-busy>
            <div class="col-md-12" v-if="loading">
                  <h6 class="sub-title mb-0 text-center">Loading...</h6>
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
              </div> 
          </template>
        </b-table>
        <!-- <service-info />  -->
      </div>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
        class="mt-2 custom-font-16"
      ></b-pagination>
    </div>
    
  </template>
  
  <script>
    import ServiceInfo from './ServiceInfo.vue'
    import DashboardApi from '../../../services/DashboardService'
    import NetworkTypeService from '../../../services/NetworkTypeService'
    import config, { networkTypeArray } from '../../../services/globalLit'
  
    export default {
      props: {
        sdate: Date | String,
        edate: Date | String,
        serData: Array
      },
      watch: {
        sdate: function () {
          this.init()
        }
      },
      components: {
        ServiceInfo
      },
      mounted() {
        this.init()
        
      },
      data() {
        return {
          loading: false,
          netOps: [],
          data: "",
          showModal: false,
          isBusy: false,
          perPage: 10,
          currentPage: 1,
          rows: 1,
          serFields: [],
          categoryArray: config.categorylist,
          dateRange: null
        }
      },
      created(){
        this.$root.$on('date-range-updated', this.onDateRangeUpdated)
      },
      beforeDestroy(){
        this.$root.$off('date-range-updated', this.onDateRangeUpdated)
      },
      watch: {
        serData: function () {
          this.init()
        }
      },
      methods: {
        async init(txt) {
          await this.getNetworkType()
          //  console.log(this.serData)
            if(this.serData.length > 0){
              let filterdata = this.serData.slice(0, 1);
             
              filterdata  = filterdata.map(({ sname, sprovidername,spid, ...rest }) => rest);
              
              let blackListedKeys = ['spid']
              let keys = Object.keys(filterdata[0]).filter(e=> !blackListedKeys.includes(e))
              this.serFields = keys.map(e => {
                let label = this.netOps[e] ? this.netOps[e] : e
               
                return {
                  key: e,
                  label: label === '_id' ? 'Service' : this.sentenceCase(label),
                  thClass: (e === '_id' || e === 'category') ? '' : 'custom-header custom-field-width',
                  tdClass: (e === '_id' || e === 'category') ? '' : 'custom-field-width',
                }
              })
              
            }
            this.rows = this.serData.length;
          
        },
        onDateRangeUpdated(dateRange){
          this.dateRange = dateRange
          this.init('dateRange')
        },
        toggleBusy() {
          this.isBusy = !this.isBusy
        },
        editTable(data) {
        // this.data = Object.assign({}, data.item);
        // this.showModal = true;
        // this.$root.$emit("openTrx", Object.assign({}, data));
        // this.$bvModal.show("openServiceInfo");
        data = data.item
        data.sid = data._id
        data.sdate = this.sdate
        data.edate = this.edate
        // console.log("data", data)
        this.$root.$emit("showtrxforlist",data);
        this.$bvModal.show("openSerList");
      },
         myRowClickHandler(record, index) {
          // 'record' will be the row data from items
          // `index` will be the visible row number (available in the v-model 'shownItems')
          log(record); // This will be the item data for the row
        },
        log(record) {
          console.log(record)
        },
        sentenceCase(str) {
          if (typeof str !== 'string') return str
          return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())
        },
        async getNetworkType() {
          let country = localStorage.getItem('country')
          let response = await NetworkTypeService.getAllNetworkType(country);
          this.netOps = []
          if(response.result){
            this.netOps = response.data
          }
          // this.serFields = [ ...[{
          //   key: "_id",
          //   label: "Services",
          //   thStyle: { color: "#000", "font-weight": "bold" },
          // }], ...networkTypeArray.map(e => {return {"key": e.name, "label": this.sentenceCase(e.name)}})]
         },
         filterCategory(category){
          if(this.categoryArray.filter(e => e.id == category)){
            return this.categoryArray.filter(e => e.id == category)[0].name
          }
         }
      }
    }
  </script>
  <style>
  .text-red {
    color: #f15a29;
    font-weight: lighter;
  }
  .table .bTableThStyle {
    background-color: #1c75bc !important;
    /* max-width: '100px' !important; */
    text-overflow: ellipsis !important;
  }
  .table > tbody > tr > td {
    max-width: 350px !important;
    overflow: auto;
    text-overflow: ellipsis;
  }
  .custom-header > div{
    max-width: 4em !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }

  .custom-header > div:hover {
    max-width: 6em !important;
    text-overflow: clip;
    white-space: normal;
  }

  .custom-field-width {
    max-width: 4rem !important;
  }
  </style>
