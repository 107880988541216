<template>
  <div>
    <b-row>
      <b-col lg="12">
        <div>
          <b-card class="custom-modal-card-2">
            <span class="custome-title">Services</span>
            
              <div class="col-md-12" v-if="serviceLoading">
                  <h6 class="sub-title mb-0 text-center">Loading...</h6>
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
              </div> 
              
            <services-table v-else :edate="edate" :sdate="sdate" :serData="serData" />
          </b-card>
        </div>
      </b-col>
      <b-col lg="12">
        <div>
          <b-card class="custom-modal-card-2">
            <b-row>
              <b-col>
                <span class="custome-title">Operator/ WIFI Performance</span>
              </b-col>

              <b-col class="text-right">
               
                <b-button
                  variant="outline-info"
                  v-if="activeIndex"
                  @click="changePieChartData"
                >
                  {{
                    "Back To " + pieChartOpt[activeIndex - 1].title
                  }}</b-button
                >
              </b-col>
            </b-row>
            <b-row>
              <div class="col-md-12" v-if="opLoading">
                  <h6 class="sub-title mb-0 text-center">Loading...</h6>
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
              </div> 
              <b-col v-else cols="12">
                <GChart
                  class="chart-overflow"
                  id="pie-chart2"
                  type="PieChart"
                  v-if="loaded"
                  ref="pieChart"
                  :data="pie_chart.chartData_1"
                  :options="pie_chart.options_2"
                  :events="chartEvents"
                />
              </b-col>
            </b-row>
          </b-card>
          <service-list></service-list>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" class="mt-0">
        <service-performance :sdate="sdate" :edate="edate"></service-performance>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" class="mt-0">
        <div>
          <b-card class="custom-modal-card-2">
            <span class="custome-title">Service Category</span>
            <!-- <b-row align-v="center" slot="header">
                <b-col>
                  <h6 class="text-light text-uppercase ls-1 mb-1">Overview</h6>
                  <h5 class="h3 text-white mb-0">Service Category</h5>
                </b-col>
                <b-col>
                  <b-nav class="nav-pills justify-content-end"> -->
            <!-- <b-nav-item
                         class="mr-2 mr-md-0"
                         :active="bigLineChart.activeIndex === 0"
                         link-classes="py-2 px-3"
                         @click.prevent="initBigChart(0)">
                        <span class="d-none d-md-block">Month</span>
                        <span class="d-md-none">M</span>
                    </b-nav-item>
                     <b-nav-item
                      link-classes="py-2 px-3"
                      :active="bigLineChart.activeIndex === 1"
                      @click.prevent="initBigChart(1)"
                    >
                    <span class="d-none d-md-block">Week</span>
                      <span class="d-md-none">W</span>
                    </b-nav-item> -->
            <!-- </b-nav>
                </b-col> -->
            <!-- </b-row> -->
            <!-- {{bigLineChart.chartData}}
              {{googleChartData}} -->
            <!-- <GChart
                class="chart-overflow"
                id="bigChart"
                type="LineChart"
                :data="googleChartData"
                :options="googleChartOptions"
              /> -->
              <div class="col-md-12" v-if="catLoading">
                  <h6 class="sub-title mb-0 text-center">Loading...</h6>
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
              </div> 
            <line-chart
            v-else
              class="p-2"
              :height="350"
              ref="bigChart"
              :chart-data="bigLineChart.chartData"
              :extra-options="bigLineChart.extraOptions"
            >
            </line-chart>
          </b-card>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" class="mt-0">
        <ad-networks :sdate="sdate" :edate="edate"></ad-networks>
      </b-col>
    </b-row>
    <!-- <b-row>
        <b-col lg="12" class="m-2">
          <div>
            <b-card title="Card Title">
              <bar-chart />
            </b-card>
          </div>
        </b-col>
      </b-row> -->
  </div>
</template>
  <script>
import { FadeTransition } from "vue2-transitions";
import LineChart from "../../components/Charts/LineChart";
import BarChart from "../../components/Charts/BarChart";
import PieChart from "../../components/Charts/PieChart";
import * as chartConfigs from "@/components/Charts/config";
import ServicesTable from "./../Dashboard/ServicesTable.vue";
import globalConfig from "../../../services/globalLit";
import Pie from "../../components/Charts/Pie.vue";
import DashboardService from "../../../services/DashboardService";
import NetworkTypeService from "../../../services/NetworkTypeService";
import ServiceList from "../../views/ServicesList.vue";
import { GChart } from "vue-google-charts";
import ServicePerformance from "../../components/Charts/servicePerformance.vue";
import AdNetworks from "../../components/Charts/adNetworks.vue";

export default {
  props: {
    sdate: {
      type: Date | String,
    },
    edate: {
      type: Date | String,
    },
  },
  watch: {
    sdate: function () {
      this.initBigChart(0);
    },
  },
  components: {
    FadeTransition,
    LineChart,
    BarChart,
    PieChart,
    ServicesTable,
    Pie,
    ServiceList,
    GChart,
    ServicePerformance,
    AdNetworks,
  },
  methods: {
    lineChartClickHandler(e, a) {
      let dataPoint = a[0];
      if (dataPoint) {
        let payload = {};
        let chart = this.$refs.bigChart._data._chart;

        let activeLineIndex = chart.getElementsAtEvent(e)[0]._datasetIndex;
        // Use getElementsAtEventForMode to get the nearest clicked point
        const elements = chart.getElementsAtEventForMode(
          e, // The event that triggered the click
          "nearest", // 'nearest' ensures only the closest element is captured
          { intersect: true }, // This ensures the click is directly on the element, not nearby
          true
        );
        let legendTitle = "";
        if (elements.length > 0) {
          const firstElement = elements[0];

          // console.log(firstElement);
          // Get dataset index and data index
          const datasetIndex = firstElement._datasetIndex;
          const dataIndex = firstElement._index;

          // Retrieve the dataset (to access the label/legend)
          const dataset = chart.data.datasets[datasetIndex];

          // console.log(dataset);
          // Retrieve the legend title (label) for the dataset
          legendTitle = dataset.label;

          // Retrieve the clicked data point value
          const value = dataset.data[dataIndex];

          // Log or use the legend title and the clicked value
          // console.log(`Legend Title: ${legendTitle}, Clicked Value: ${value}`);

          // You can add further logic here (e.g., showing a tooltip, routing, etc.)
        }

        let label = this.bigLineChart.chartData.labels[dataPoint._index];

        payload.category = legendTitle;
        payload.sdate = new Date(label).setHours(0, 0, 0, 0);
        payload.edate = new Date(label).setUTCHours(23, 59, 59, 999);
        this.$root.$emit("showtrxforlist", payload);
        this.$bvModal.show("openSerList");
      }
    },
    async getServiceData() {
      let payload = {};

      payload.sdate = this.sdate || this.dateRange.startDate;
      payload.edate = this.edate || this.dateRange.endDate;
      this.serviceLoading = true;
      
      try {
      let response = await DashboardService.networkServices(payload);
      // console.log(response)
      if (response.result) {
        this.serData = response.data;
      }
      } catch (e) {
        console.error("Error in getting service data")
        console.error(e)
      }
      this.serviceLoading = false;
      
    },
    async changePieChartData() {
      this.activeIndex--;

      if (this.activeIndex < 0) {
        this.activeIndex = 0;
      }
      if (this.activeIndex == 0) {
        this.getOperatorChartData();
      } else if (this.activeIndex == 1) {
        this.changeDataToCSP();
      } else if (this.activeIndex == 2) {
        this.changeDataToServices();
      }

      this.pie_chart.options_2.pieStartAngle = 90;
      let t = setInterval(() => {
        this.pie_chart.options_2.pieStartAngle--;
        if (this.pie_chart.options_2.pieStartAngle <= 0) {
          clearInterval(t);
        }
      }, 1);
    },
    async filterService() {
      let selection = this.$refs.pieChart.chartObject.getSelection();
      
      if (selection.length > 0) {
        this.activeIndex++;
        if (this.activeIndex > this.pieChartOpt.length - 1) {
          this.activeIndex = this.pieChartOpt.length;
        }

        const selectedItem = selection[0];
        // console.log( "selected item: ", selectedItem)
        // console.log(this.pie_chart.chartData_1[selectedItem.row + 1])

        if (this.activeIndex == 1) {
          this.netTypeId = this.pie_chart.chartData_1[selectedItem.row + 1][2]; // +1 to skip header row
          this.opLabel = this.pie_chart.chartData_1[selectedItem.row + 1][0];
          this.changeDataToCSP();
        } else if (this.activeIndex == 2) {
          this.pie_chart.options_2.pieStartAngle = 0;

          this.spid = this.pie_chart.chartData_1[selectedItem.row + 1][2];
          this.opLabel = this.pie_chart.chartData_1[selectedItem.row + 1][3];
          this.netTypeId = this.pie_chart.chartData_1[selectedItem.row + 1][4];
          this.spName = this.pie_chart.chartData_1[selectedItem.row + 1][0];

          this.changeDataToServices();
        } else {
          this.activeIndex--;
          this.opLabel = this.pie_chart.chartData_1[selectedItem.row + 1][3];
          this.netTypeId = this.pie_chart.chartData_1[selectedItem.row + 1][4];
          let sid = this.pie_chart.chartData_1[selectedItem.row + 1][2];

          let payload = {};
          payload.netType = this.netTypeId;
          payload.sdate = this.sdate;
          payload.edate = this.edate;
          payload.sid = sid;

          payload.sprovidername =
            this.pie_chart.chartData_1[selectedItem.row + 1][5];
          payload.sname = this.pie_chart.chartData_1[selectedItem.row + 1][0];

          payload.operator = this.opLabel;
          this.$root.$emit("showtrxforlist", payload);
          this.$bvModal.show("openSerList");
        }
      } else {
        console.log("No selection");
      }
      return;
    },
    changeDataToCSP() {
      //filter csp data from the serData
      let cspData = this.serData.reduce((acc, curr) => {
        if (!acc[curr["spid"]]) {
          acc[curr["spid"]] = {
            total: curr[this.netTypeId],
            title: curr["sprovidername"],
          };
        } else {
          acc[curr["spid"]].total += curr[this.netTypeId];
        }
        return acc;
      }, {});

      let t = setInterval(() => {
        this.pie_chart.options_2.pieStartAngle++;
        if (this.pie_chart.options_2.pieStartAngle >= 45) {
          clearInterval(t);
        }
      }, 1);

      this.pie_chart.chartData_1 = Object.keys(cspData).map((e) => [
        cspData[e].title,
        cspData[e].total,
        e,
        this.opLabel,
        this.netTypeId,
      ]);
      this.pie_chart.chartData_1.unshift([
        "CSP",
        "Total",
        "spid",
        "operator",
        "netTypeId",
      ]);
    },
    changeDataToServices() {
      let spFilter = this.serData.filter((e) => e.spid == this.spid);
      let serviceData = spFilter.reduce((acc, curr) => {
        if (!acc[curr["_id"]]) {
          acc[curr["_id"]] = {
            total: curr[this.netTypeId],
            title: curr["sname"],
          };
        } else {
          acc[curr["_id"]].total += curr["total"];
        }
        return acc;
      }, {});

      let t = setInterval(() => {
        this.pie_chart.options_2.pieStartAngle++;
        if (this.pie_chart.options_2.pieStartAngle >= 45) {
          clearInterval(t);
        }
      }, 1);

      this.pie_chart.chartData_1 = Object.keys(serviceData).map((e) => [
        serviceData[e].title,
        serviceData[e].total,
        e,
        this.opLabel,
        this.netTypeId,
        this.spName,
      ]);
      this.pie_chart.chartData_1.unshift([
        "Service",
        "Total",
        "sid",
        "operator",
        "netTypeId",
        "spName",
      ]);
    },
    getArrayData(labelArray, recDataObj) {
      let dataArray = [];

      for (let i = 0; i < labelArray.length; i++) {
        let isRecordFound = recDataObj
          ? recDataObj.find((e) => e.date.split("T")[0] == labelArray[i])
          : false;
        if (isRecordFound) {
          dataArray.push(isRecordFound.total);
        } else {
          dataArray.push(0);
        }
      }
      return dataArray;
    },
    async initBigChart(index) {
      await this.getNetworkType();
      await this.getServiceData();

      let today = this.edate.toISOString().split("T")[0];
      let lastDate = this.sdate.toISOString().split("T")[0];
  
      today = new Date(today);
      lastDate = new Date(lastDate); 
      let diffInDays = Math.floor(
        (today - lastDate) / (1000 * 60 * 60 * 24)
      );  
      let label = [today.toISOString().split("T")[0]];

      for (let i = 0; i < diffInDays; i++) {
        label.push(
          new Date(today.setDate(today.getDate() - 1))
            .toISOString()
            .split("T")[0]
        );
      }
      label = label.reverse();
      let categoryPayload = {
        sdate: this.sdate,
        edate: this.edate,
      };
      this.catLoading = true;
      let categoryStatRes = {}
      
      try {
        categoryStatRes = await DashboardService.categoryStat(
        categoryPayload
      );
      } catch (e) {
        console.error("Error in getting category data")
        console.error(e)
      }
      
      this.catLoading = false;
      if (categoryStatRes.result) {
        let googleChartLabel = [
          "Date",
          ...globalConfig.categorylist.map((e) => e.name),
        ];
        this.googleChartData.push(googleChartLabel);

        let allData = categoryStatRes.data;
        let allCatKey = Object.keys(allData);

        let datasets = [];
        for (let i = 0; i < globalConfig.categorylist.length; i++) {
          let rec = {};
          let catData = globalConfig.categorylist[i];
          rec.label = catData.name;
          rec.backgroundColor = catData.color;
          rec.borderColor = catData.color;
          rec.pointStyle = catData.pointStyle;
          rec.pointRadius = 5;
          rec.pointHoverRadius = 10;

          rec.data = this.getArrayData(label, allData[catData["id"]]);
          datasets.push(rec);
        }

        for (let i = 0; i < label.length; i++) {
          let rec = [label[i], ...datasets.map((e) => e.data[i])];
          this.googleChartData.push(rec);
        }

        let chartData = {
          datasets: datasets,
          labels: label,
        };
        this.bigLineChart.chartData = chartData;
      }

      await this.getOperatorChartData();
    },

    async getOperatorChartData() {
      this.loaded = false;
      this.loaded = true;
      this.opLoading = true;
      let networkIds = Object.keys(this.netOps);
      let data = this.serData.reduce((acc, curr) => {
        for (let i = 0; i < networkIds.length; i++) {
          if (!acc[networkIds[i]]) {
            acc[networkIds[i]] = {
              total: curr[networkIds[i]],
              title: this.netOps[networkIds[i]],
            };
          } else {
            acc[networkIds[i]].total += curr[networkIds[i]];
          }
        }

        return acc;
      }, {});

      let t = setInterval(() => {
        this.pie_chart.options_2.pieStartAngle++;
        if (this.pie_chart.options_2.pieStartAngle >= 45) {
          clearInterval(t);
        }
      }, 1);

      this.pie_chart.chartData_1 = Object.keys(data).map((e) => [
        data[e].title,
        data[e].total,
        e,
      ]);
      this.pie_chart.chartData_1.unshift([
        "operators",
        "no_of_operators",
        "ids",
      ]);

      this.opLoading = false
    },
    async getNetworkType() {
      let country = localStorage.getItem("country");
      let response = await NetworkTypeService.getAllNetworkType(country);
      this.netOps = {};
      if (response.result) {
        this.netOps = response.data;
      }
      this.pieChartLabel = Object.values(this.netOps);
    },
  },
  created() {},
  mounted() {
    this.initBigChart(0);
  },
  data() {
    return {
      serviceLoading: true,
      catLoading: true,
      opLoading: true,
      opId: "",
      netOps: {},
      netTypeId: "",
      opLabel: "",
      spid: "",
      spName: "",
      serData: [],
      pieChartOpt: [
        { title: "Operator", active: true },
        { title: "CSP", active: false },
        { title: "Services", active: false },
      ],
      activeIndex: 0,
      cspVisible: false,
      netOpVisible: false,
      googleChartData: [],
      chartEvents: {
        select: this.filterService,
      },
      pie_chart: {
        chartData_1: [],
        options_2: {
          pieStartAngle: 0,
          height: 400,
          chartArea: { left: 15, top: 15, right: 0, bottom: 0 },
          legend: {
            position: "right",
            alignment: "center",
            textStyle: {
              color: "#233238",
              fontSize: 18,
            },
          },
          is3D: true,
          colors: [
            "#8979FF",
            "#FF928A",
            "#3CC3DF",
            "#FFAE4C",
            "#537FF1",
            "#6FD195",
            "#FF6F61",
            "#C4E17F",
            "#FFDC61",
            "#AF7AC5",
          ],
        },
      },
      googleChartOptions: {
        width: "100%",
        height: 400,
        curveType: "function",
        chartArea: { left: 30, right: 0 },
        legend: { position: "bottom" },
      },
      networkTypeArray: [],
      pieChartLabel: "",
      lineChartLabel: [],
      loaded: false,
      pieChartData: [0, 0, 0, 0],
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
        chartData: {
          datasets: [
            {
              label: "Performance",
              borderColor: "red",
              data: [300, 20, 10, 30, 15, 40, 20, 60],
            },
            {
              label: "test",
              data: [30, 1, 44, 44, 12, 312, 123, 4],
            },
            {
              label: "t",
              borderColor: "",
              data: [50, 1, 50, 30, 15, 40, 20, 60],
            },
          ],
          labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        },
        extraOptions: {
          onClick: this.lineChartClickHandler,
        },
      },
      lineChart: {
        data: {
          labels: [
            "9:00AM",
            "12:00AM",
            "3:00PM",
            "6:00PM",
            "9:00PM",
            "12:00PM",
            "3:00AM",
            "6:00AM",
          ],
          series: [
            [287, 385, 490, 492, 554, 586, 698, 695],
            [67, 152, 143, 240, 287, 335, 435, 437],
            [23, 113, 67, 108, 190, 239, 307, 308],
          ],
        },
        options: {
          low: 0,
          high: 800,
          showArea: false,
          height: "245px",
          axisX: {
            showGrid: false,
          },
          lineSmooth: true,
          showLine: true,
          showPoint: true,
          fullWidth: true,
          chartPadding: {
            right: 50,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
    };
  },
};
</script>
  
