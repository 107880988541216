<template>
  <b-card class="custom-modal-card-2">
    <span class="custome-title">Services Performance</span>
     <div class="col-md-12" v-if="serPerLoading">
                  <h6 class="sub-title mb-0 text-center">Loading...</h6>
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
              </div> 
    <line-chart v-else class="p-2" :height="450" ref="bigChart" :chart-data="bigLineChart.chartData" :extra-options="bigLineChart.extraOptions">
    </line-chart>
  </b-card>
</template>

<script>
import LineChart from './LineChart';
import DashboardService from '../../../services/DashboardService';
import NetworkTypeService from '../../../services/NetworkTypeService';
import globalConfig from '../../../services/globalLit';

export default {
  props: {
    sdate: {
      type: Date | String,
    },
    edate: {
      type: Date | String,
    },
  },
  watch: {
    sdate: function () {
      this.initBigChart(0);
    },
  },
  components: {
    LineChart
  },
  created() {},
  mounted() {
    this.initBigChart(0);
  },
  data() {
    return {
      serPerLoading: true,
      netOps: {},
      googleChartData: [],
      bigLineChart: {
        allData: [],
        activeIndex: 0,
        chartData: {
          labels: [],
          datasets: [],
          
        },
        extraOptions: {
          onClick: this.lineChartClickHandler,
        },
      },
    };
  },
  
  methods: {
    lineChartClickHandler(e, a) {
      let dataPoint = a[0];
      if (dataPoint) {
        let payload = {};
        let chart = this.$refs.bigChart._data._chart;

        let activeLineIndex = chart.getElementsAtEvent(e)[0]._datasetIndex;
        
        const elements = chart.getElementsAtEventForMode(
          e,
          "nearest",
          { intersect: true },
          true
        );
        let legendTitle = "";
        let sId = "";
        if (elements.length > 0) {
          const firstElement = elements[0];

          const datasetIndex = firstElement._datasetIndex;
          const dataIndex = firstElement._index;

          const dataset = chart.data.datasets[datasetIndex];

          legendTitle = dataset.label;
          sId = dataset.sid;
          const value = dataset.data[dataIndex];
        }

        let label = this.bigLineChart.chartData.labels[dataPoint._index];

        payload.category = legendTitle;
        payload.sid = sId;
        payload.sdate = new Date(label).setHours(0, 0, 0, 0);
        payload.edate = new Date(label).setUTCHours(23, 59, 59, 999);
        this.$root.$emit("showtrxforlist", payload);
        this.$bvModal.show("openSerList");
      }
    },
    async getServiceData() {
      let payload = {};

      payload.sdate = this.sdate;
      payload.edate = this.edate;
      this.serPerLoading = true;
      try {
          let response = await DashboardService.networkServices(payload);
          // console.log(response)
          if (response.result) {
              this.serData = response.data;
          }
      } catch (e) {
        console.log(e);
      } finally {
        this.serPerLoading = false;
      }
     
    },
    getArrayData(labelArray, recDataObj) {
      let dataArray = [];

      for (let i = 0; i < labelArray.length; i++) {
        let isRecordFound = recDataObj
          ? recDataObj.find((e) => e.date.split("T")[0] == labelArray[i])
          : false;
        if (isRecordFound) {
          dataArray.push(isRecordFound.total);
        } else {
          dataArray.push(0);
        }
      }
      return dataArray;
    },
    async initBigChart(index) {
      await this.getNetworkType();
      await this.getServiceData();

      let today = this.edate.toISOString().split("T")[0];
      let lastDate = this.sdate.toISOString().split("T")[0];
  
      today = new Date(today);
      lastDate = new Date(lastDate); 
      let diffInDays = Math.floor(
        (today - lastDate) / (1000 * 60 * 60 * 24)
      );  
      let label = [today.toISOString().split("T")[0]];

      for (let i = 0; i < diffInDays; i++) {
        label.push(
          new Date(today.setDate(today.getDate() - 1))
            .toISOString()
            .split("T")[0]
        );
      }
      label = label.reverse();

      let servicePayload = {
        sdate: this.sdate,
        edate: this.edate,
      };
      let serviceStatRes = await DashboardService.serviceStat(
        servicePayload
      );
      if (serviceStatRes.result) {
        let allData = serviceStatRes.data;

        let snameLabels = [];
        for (let serviceId in allData) {
            allData[serviceId].forEach(item => {
                let serviceLabel = `${item.spname} - ${item.sname}`;
                if (!snameLabels.includes(serviceLabel)) {
                  snameLabels.push(serviceLabel);
                }
            });
        }

        let googleChartLabel = [
          "Date",
          ...snameLabels.map((e) => e),
        ];
        
        this.googleChartData.push(googleChartLabel);

        let datasets = [];
        let sidArray = Object.keys(allData)

        for (let i in sidArray) {
            let rec = {};
            let serviceId = sidArray[i]
            let serviceData = allData[serviceId];

            rec.label = snameLabels[i];
            
            rec.backgroundColor = this.getRandomColor();
            rec.borderColor = rec.backgroundColor;
            rec.pointStyle = 'circle';
            rec.pointRadius = 5;
            rec.pointHoverRadius = 10;
            rec.sid = serviceId;
            // rec.data = serviceData.map(item => item.total
            // );
            
            rec.data = this.getArrayData(label, serviceData);
            datasets.push(rec);
        }


        for (let i = 0; i < label.length; i++) {
          let rec = [label[i], ...datasets.map((e) => e.data[i])];
          this.googleChartData.push(rec);
        }

        let chartData = {
          datasets: datasets,
          labels: label,
        };
        this.bigLineChart.chartData = chartData;
      }
    },
    async getNetworkType() {
      let country = localStorage.getItem("country");
      let response = await NetworkTypeService.getAllNetworkType(country);
      this.netOps = {};
      if (response.result) {
        this.netOps = response.data;
      }
    },
    getRandomColor() {
      let r = Math.floor(Math.random() * 256);
      let g = Math.floor(Math.random() * 256);
      let b = Math.floor(Math.random() * 256);
      return `rgb(${r}, ${g}, ${b})`;
    },
  }
}
</script>

<style scoped>
.custome-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
</style>